<template>
  <div class="home">
    <WebsiteHeaderVue @id='handleMove' :address='address'></WebsiteHeaderVue>
    <div class="banner">
      <div class="role-bg container">
        <div class="angel"></div>
        <div class="position">
          <h1>Welfare.DAO</h1>


          <div>{{ $t('web.banner.text') }}</div>
          <router-link :to="{path: '/sfomo', query: {referee}}" class="btn-launch">
            超级 FOMO
          </router-link>

          <div style="height: 5px"></div>
          <router-link :to="{path: '/fomo', query: {referee}}" class="btn-launch">
            FOMO
          </router-link>



          <div style="height: 5px"></div>
          <div class="btn-launch" v-if="this.address">
            {{ $t('web.banner.ljCG') }}
          </div>

          <div class="btn-launch" @click="interlinkage" v-else>
            {{ $t('web.banner.ljQB') }}
          </div>

          <div class="btn-launch" @click="handleBind" v-if="dialogVisible">
            <!-- 绑定邀请 -->
            {{ $t('web.banner.bdYQ') }}
          </div>
          <router-link v-else :to="{path: '/invest', query: {referee}}" class="btn-launch">
            <!-- 启动 -->
            {{ $t('web.banner.launch') }}
          </router-link>

          <div style="height: 5px"></div>


        </div>
      </div>
    </div>
    <div class="content">
      <div class="container">
        <div class="team" ref="team">
          <div class="title">{{ $t('web.team.title') }}</div>
          <div class="info">
            <div class="link">
              <div class="sub-title">{{ $t('web.team.subTitle') }}</div>
              <div>{{ url | addressCharacter(15, 6) }} <i @click="handleCopyClick"></i></div>
            </div>
            <div class="total">
              <div class="sub-title">{{ $t('app.mine.level') }}</div>
              <div>V{{ level }}</div>
            </div>
            <div class="total">
              <div class="sub-title">{{ $t('web.team.power') }}</div>
              <div>{{ total | numFilter2 }}</div>
            </div>
          </div>
          <div class="sub-title">{{ $t('web.team.subTitle2') }}</div>
          <template v-if="totalPage >= 1">
            <ul class="sub-list">
              <li>
                <span>{{ $t('web.team.address') }}</span>
                <span>{{ $t('web.team.sub') }}</span>
              </li>
              <li v-for="item in subPageList" :key="item.address">
                <span>{{ item.address | addressCharacter(6 , 6) }}</span>
                <span>{{ item.power | numFilter6 }}</span>
              </li>
            </ul>
            <div class="pagination">
              <div class="pre" @click="handleExchangePage(-1)">{{ $t('com.pre') }}</div>
              <div class="page"><span>{{ page }}</span> / {{ totalPage }}</div>
              <div class="next" @click="handleExchangePage(1)">{{ $t('com.next') }}</div>
            </div>
          </template>
          <div class="empty" v-else>
            <div class="empty-bg"></div>
            <div class="tips">{{ $t('com.empty') }}</div>
          </div>
          <!-- :small="true" -->
          <!-- <el-pagination
            background
            layout="prev, pager, next"
            :total="1000"
            :pager-count="5"
            :small="true"
            :hide-on-single-page="true">
          </el-pagination> -->
        </div>
        <div class="title" ref="NFT">{{ $t('web.market.title') }}</div>
        <ul class="DAO">
          <li>
            <span>{{ $t('web.market.text1') }}</span>
          </li>
          <li>
            <span>{{ $t('web.market.text2') }}</span>
          </li>
          <li>
            <span>{{ $t('web.market.text3') }}</span>
          </li>
        </ul>
        <div class="title" ref="pool">{{ $t('web.pool.title') }}</div>
        <div class="LP-img">
          <div class="sub-text">
            <div>{{ $t('web.pool.text1') }}</div>
            <div>{{ $t('web.pool.text2') }}</div>
          </div>
        </div>

        <div class="title" ref="help">{{ $t('web.help.title') }}</div>
        <div class="right">
          <div class="help-img">
            <div class="sub-text">
              <div>{{ $t('web.help.text1') }}</div>
              <div>{{ $t('web.help.text2') }}</div>
            </div>
          </div>
        </div>

        <div class="title" ref="crowd">{{ $t('web.crowd.title') }}</div>
        <div class="sub-text">{{ $t('web.crowd.subTitle') }}</div>
        <ul class="crowd">
          <li>
            <div class="img"></div>
            <div class="name">{{ $t('web.crowd.name1') }}</div>
          </li>
          <li>
            <div class="img"></div>
            <div class="name">{{ $t('web.crowd.name2') }}</div>
          </li>
        </ul>

        <div class="title" ref="swap">{{ $t('web.swap.title') }}</div>
        <div class="swap-img">
          <div class="sub-text">
            <div>{{ $t('web.swap.subTitle1') }}</div>
            <div>{{ $t('web.swap.subTitle2') }}</div>
          </div>
        </div>
        <ul class="swap-ul">
          <li ref="wallet">
            <div class="img"></div>
            <div class="info">
              <div class="swap-title">{{ $t('web.swap.info[0].title') }}</div>
              <div class="text">{{ $t('web.swap.info[0].text') }}</div>
            </div>
          </li>
          <li ref='GameFi'>
            <div class="info">
              <div class="swap-title">{{ $t('web.swap.info[1].title') }}</div>
              <div class="text">{{ $t('web.swap.info[1].text') }}</div>
            </div>
            <div class="img"></div>
          </li>
        </ul>

        <footer>
          <div class="line"></div>
          <div class="logo">
            <img src="../../assets/img/web-logo.png">
            <span>{{ $t('web.footer.name') }}</span>
          </div>
          <div class="footer-text">
            {{ $t('web.footer.welcome') }}
          </div>
          <div class="contact">
            <a class="twitter" href="https://twitter.com/WelfareDAO"></a>
            <a class="teleground" href="https://t.me/welfareDAO_CN"></a>
          </div>
        </footer>

      </div>
    </div>
  </div>
</template>

<script>
import WebsiteHeaderVue from '@/components/WebsiteHeader.vue'
import { ethers } from 'ethers'
// import Community from '../../contracts/Community.json'
import Bind from '../../contracts/Bind.json'
import { initSigner } from '../../utlis/index'

export default {
  name: 'Home',
  data() {
    return {
      address: '',
      contracts: {},
      referee: '',
      total: 0,         // 直推总金额
      subList: [],      // 下级地址
      page: 1,          // 当前分页
      pageSize: 3,      // 分页总条目数
      level: 0,
      referee1: this.$route.query.referee || '0x0000000000000000000000000000000000000000',
      dialogVisible:false
    }
  },
  computed: {
    // 邀请地址
    url() {
      if (!this.address) return '-'
      let url = location.origin + '?referee=' + this.address
      return url
    },
    // 分页总页数
    totalPage() {
      return Math.ceil(this.subList.length / this.pageSize)
    },
    // 当前分页条目
    subPageList() {
      return this.subList.slice((this.page - 1) * this.pageSize, this.page * this.pageSize)
    },
  },
  mounted() {
    this.referee = this.$route.query.referee || '0x0000000000000000000000000000000000000000'
    let self = this,
      tries = 0;
    setTimeout(function initTimer() {
      if (!window.web3 && ++tries < 10) return setTimeout(initTimer, 100);
      self.init()
    })
  },
  beforeDestroy() {
    clearInterval(this.initTimer)
  },
  methods: {
    async init() {
      let { address, provider, signer } = await initSigner()
      this.address = address

      // this.contracts.Community = new ethers.Contract(this.$common.Community, Community, provider).connect(signer)
      this.contracts.Bind = new ethers.Contract(this.$common.Bind, Bind, provider).connect(signer)

      this.getData()
      clearInterval(this.initTimer)
      this.initTimer = setInterval(() => {
        this.getData()
      }, 20000)
    },
    async getData() {
      if (!this.address) return
      // 直推奖励

      // this.contracts.Community.underNumber(this.address).then(res => {
      //   let num = ethers.utils.formatUnits(res, 18)
      //   this.total = num
      // })
      // // 获取下级地址
      // this.contracts.Community.getSubordinateReward(this.address).then(res => {
      //   // console.log(res);
      //   let arr = []
      //   for (const key in res[0]) {
      //     arr.push({
      //       address: res[0][key],
      //       power: ethers.utils.formatUnits(res[1][key], 18)
      //     })
      //   }
      //   this.subList = arr
      // })
      // 账号等级
      // this.contracts.Community.level(this.address).then(res => {
      //   this.level = Number(res)
      // })
      //判断是否绑定上级
      // this.contracts.Bind.isActive(this.address).then(res => {
      //   this.dialogVisible = !res
      //   // console.log(this.dialogVisible,'是否绑定');
      // })
    },
    // 切换分页
    handleExchangePage(num) {
      if (this.totalPage <= 1) {
        return false
      } else if (this.page + num > this.totalPage) {
        this.page = 1
      } else if (this.page + num < 1) {
        this.page = this.totalPage
      } else {
        this.page += num
      }
    },
    // 复制邀请链接
    handleCopyClick() {
      let input = document.createElement("input");
      input.value = this.url;
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message.success('粘贴到剪切板')
    },
    //邀请绑定
    async handleBind() {
      // let reg = /^0x[a-fA-F0-9]{40}$/g
      // if (!this.referee) return this.$message.warning(this.$t('msg.bind'))
      // else if (!reg.test(this.referee)) return this.$message.warning(this.$t('msg.invit'))
      // else if (this.referee !== '0x0000000000000000000000000000000000000000') {
      //   try {
      //     let res = await this.contracts.Bind.isActive(this.referee)
      //     if (!res) {
      //       return this.$message.error(this.$t('msg.invit'))
      //     }
      //   } catch (error) {
      //     console.info('校验地址是否已绑定,传递参数' + this.referee + '\n', error);
      //     return this.$message.error(this.$t('msg.invalidAddress'))
      //   }
      // }
      // this.contracts.Bind.bindRelationship(this.referee).then(async (result) => {
      //   await result.wait()
      //   this.dialogVisible = false
      //   this.getData()
      // }).catch((err) => {
      //   console.info('绑定上级地址,传递参数' + this.referee + '\n', err);
      //   this.$message.error(this.$t('msg.invalidAddress'))
      // });
    },
    /**
     * 锚点
     * ref      dom
     * height   页头高度
     */
    handleMove(key, height) {
      // console.log(this.$refs[key]);
      let top = this.$refs[key].offsetTop - height
      window.scroll({
        top,
        left: 0,
        behavior: 'smooth'
      })
    },
    //链接钱包
  interlinkage() {
    // initSigner()
    let { ethereum} = window
    console.log(ethereum);
    ethereum.request({ method: 'eth_requestAccounts' });
    }
  },
  components: {
    WebsiteHeaderVue
  }
}
</script>


<style lang="scss" scoped>
.home {

  // min-height: 100vh;
  .empty {
    font-size: 0.28rem;
    padding: 0.4rem 0;

    .empty-bg {
      width: 2.9rem;
      height: 1.87rem;
      margin: 0 auto;
      background: url('../../assets/img/empty.png') left top / 100% 100% no-repeat;
    }

    .tips {
      margin-top: 0.2rem;
      text-align: center;
    }
  }

  .banner {
    width: 100%;
    // height: 3.14rem;
    // height: 100vh;
    color: #fff;
    padding-top: 1.2rem;
    text-align: center;
    overflow: hidden;
    background: url('../../assets/img/banner-bg.png') left top / 100% 100% no-repeat;
    padding-bottom: 2.8rem;

    .role-bg {
      width: 100%;
      height: 9rem;
      padding: 0;
      position: relative;

      .angel {
        // position: absolute;
        // left: 0;
        // top: 0;
        // bottom: 0;
        // width: 10.2rem;
        width: 4.7rem;
        height: 5.9rem;
        margin: 0 auto;
        background: url('../../assets/img/angel.png') left top / 100% 100% no-repeat;
      }

      .position {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        bottom: -0.7rem;

        h1 {
          font-size: 0.36rem;
          margin: 0 auto 20px;
        }

        &>div {
          font-size: 0.36rem;
          margin-bottom: 0.5rem;
        }

        .btn-launch {
          display: inline-block;
          width: 3.65rem;
          height: 0.9rem;
          line-height: 0.9rem;
          font-size: 0.48rem;
          background-color: rgba(85, 114, 241, 0.8);
          box-sizing: border-box;
          border: 1px solid #45BBF3;
          position: relative;

          &::after {
            content: ' ';
            position: absolute;
            left: 0.08rem;
            top: 0.08rem;
            width: 100%;
            height: 100%;
            border: 1px solid #B285ED;
          }
        }
      }
    }
  }

  .content {
    background: url('../../assets/img/content-bg.png') left top / cover no-repeat;
    color: #FFF;
  }

  .container {
    padding: 0.1rem 0.4rem 0.2rem;

    // position: relative;
    .title {
      width: 5.74rem;
      height: 1.66rem;
      line-height: 1.28rem;
      font-size: 0.36rem;
      text-align: center;
      font-weight: bold;
      margin: 0 auto;
      position: relative;
      background: url('../../assets/img/web-title-bg.png') left top / 100% 100% no-repeat;

      // margin-bottom: 0.32rem;
      &::after {
        content: ' ';
        position: absolute;
        width: 5.64rem;
        height: 0.24rem;
        left: 50%;
        transform: translateX(-50%);
        top: -0.3rem;
        background: url('../../assets/img/web-title-line.png') left top / 100% 100% no-repeat;
      }
    }

    .team {
      padding: 0.2rem 0.3rem 0.3rem;
      font-size: 0.28rem;
      margin-bottom: 0.42rem;
      margin-top: -1.5rem;

      .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.3rem;

        .link {
          font-size: 0.28rem;

          div {
            font-size: 0.24rem;

            &:last-child {
              display: flex;
              align-items: center;
              color: #45BBF3;

              i {
                width: 0.38rem;
                height: 0.38rem;
                margin-left: 0.25rem;
                cursor: pointer;
                background: url('../../assets/img/web-copy.png') left top / 100% 100% no-repeat;
              }
            }
          }
        }

        .total {
          font-size: 0.24rem;

          div:last-child {
            color: #45BBF3;
            text-align: center;
          }
        }
      }

      .sub-list {
        display: flex;
        flex-direction: column;
        align-items: center;

        li {
          height: 0.7rem;
          line-height: 0.7rem;
          font-size: 0.24rem;
          text-align: center;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;

          span {
            width: 2.5rem;
            text-align: center;
          }
        }
      }

      .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.22rem;
        margin: 0.2rem 0 0.6rem;

        .page {
          width: 2rem;
          text-align: center;

          span {
            color: #45BBF3;
          }
        }

        .pre,
        .next {
          cursor: pointer;

          &:hover {
            color: #45BBF3;
          }
        }
      }
    }

    .DAO {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1.13rem;

      li {
        width: 1.94rem;
        height: 2.88rem;
        font-size: 0.22rem;
        position: relative;

        span {
          position: absolute;
          bottom: 0.22rem;
          left: 50%;
          transform: translateX(-50%);
          display: block;
          text-align: center;
          white-space: nowrap;
          color: #FFF;
        }

        &:nth-child(1) {
          background: url('../../assets/img/NFT-img1.png') left top / 100% 100% no-repeat;
        }

        &:nth-child(2) {
          background: url('../../assets/img/NFT-img2.png') left top / 100% 100% no-repeat;
        }

        &:nth-child(3) {
          background: url('../../assets/img/NFT-img3.png') left top / 100% 100% no-repeat;
        }
      }
    }

    .help-img,
    .LP-img,
    .swap-img {
      width: 5.18rem;
      height: 3.16rem;
      margin-bottom: 1.25rem;

      display: flex;
      align-items: flex-end;

      .sub-text {
        font-size: 0.24rem;
        line-height: 0.3rem;
      }
    }

    .LP-img {
      background: url('../../assets/img/LP-img.png') left top / 100% 100% no-repeat;

      .sub-text {
        margin-left: 0.3rem;
        text-align: left;
      }
    }

    .right {
      display: flex;
      justify-content: flex-end;
    }

    .help-img {
      justify-content: flex-end;
      background: url('../../assets/img/web-help.png') left top / 100% 100% no-repeat;

      .sub-text {
        margin-right: 0.3rem;
        text-align: right;
      }
    }

    .swap-img {
      width: 6.5rem;
      margin: 0 auto 0.68rem;
      align-items: flex-start;
      background: url('../../assets/img/swap-img.png') left top / 100% 100% no-repeat;

      .sub-text {
        margin-top: 0.35rem;
        margin-left: 0.25rem;
        text-align: left;
      }
    }


    .sub-text {
      font-size: 0.24rem;
      text-align: center;
      margin-top: -0.5rem;
      margin-bottom: 0.46rem;
    }

    .crowd {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 1.2rem;

      li {
        font-size: 0.3rem;

        .name {
          width: 2.32rem;
          height: 0.62rem;
          line-height: 0.62rem;
          margin: 0 auto;
          text-align: center;
          background: url('../../assets/img/crowd-btn.png') left top / 100% 100% no-repeat;
        }

        .img {
          width: 2.98rem;
          height: 3.9rem;
          margin-bottom: 0.12rem;
          background: url('../../assets/img/angel-1.png') left top / 100% 100% no-repeat;
        }

        &:last-child {
          .img {
            background-image: url('../../assets/img/angel-2.png');
          }
        }
      }
    }

    .swap-ul {
      display: flex;
      flex-direction: column;

      li {
        display: flex;
        margin-bottom: 0.6rem;

        .img {
          width: 3.18rem;
          height: 3.14rem;
          background: url('../../assets/img/wallet-img.png') left top / 100% 100% no-repeat;
        }

        .info {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: right;

          .swap-title {
            font-size: 0.3rem;
            font-weight: bold;
            margin-bottom: 0.2rem;
          }

          .text {
            font-size: 0.24rem;
          }
        }

        &:last-child {
          .img {
            background: url('../../assets/img/gamefi-img.png') left top / 100% 100% no-repeat;
          }

          .info {
            text-align: left;
          }
        }
      }
    }
  }


  footer {
    font-size: 0.24rem;
    margin-bottom: 1rem;

    .line {
      width: 5.64rem;
      height: 0.24rem;
      margin: 0 auto 0.12rem;
      background: url('../../assets/img/web-title-line.png') left top / 100% 100% no-repeat;
    }

    .logo {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      margin-bottom: 0.24rem;

      img {
        width: 1.14rem;
        height: 0.7rem;
        margin-right: 0.24rem;
      }

      span {
        color: #8B8B8B;
        font-size: 0.36rem;
      }
    }

    .footer-text {
      width: 3.2rem;
      color: #FFFF;
      margin: 0 auto 0.35rem;
      text-align: center;
    }

    .contact {
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;

      a {
        width: 0.64rem;
        height: 0.64rem;
        background-size: 100% 100%;
        background-repeat: no-repeat;
      }

      .twitter {
        background-image: url('../../assets/img/contact-img1.png');
      }

      .teleground {
        background-image: url('../../assets/img/contact-img2.png');
      }
    }
  }

}
</style>
